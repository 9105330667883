import React, { useContext, useState, useRef, useCallback, useMemo, useEffect } from "react";
import { Cascader, Button, Select, Popover, Divider, Space, Table, message, Popconfirm } from "antd";
import { Tabs } from "antd";
import { Typography } from "antd";
import { deepCopy, floydWarshallAllShortestPaths } from "../../utils/utils.js";
import { rootURI } from "../../utils/common_definitions.js";
import { QuestionSelect } from "./QuestionList.jsx";
import { Charting } from "./Charting.jsx";
import { AgGridReact } from "ag-grid-react";
import { ModuleRegistry } from "@ag-grid-community/core";
import "ag-grid-enterprise";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import CustomStatsToolPanel from "./CustomStatsToolPanel.jsx";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { DatasourcePopover } from "..";
const { Option } = Select;
import UserContext from "../../Contexts.js";

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
]);

const DatasourceList = ({ userInfo, setUserInfo, setLoading }) => {
    const { userObject, setUserObject } = useContext(UserContext);
    const [ newDSPopoverOpen, setNewDSPopoverOpen ] = useState(false);
    const [ editDSPopoverOpen, setEditDSPopoverOpen ] = useState(false);
    const [ currentDatasourceId, setCurrentDatasourceId ] = useState(-1);
    const [ code, setCode ] = useState('SQL will display here once a question is modified.');
    const [ columnDefs, setColumnDefs ] = useState([
        { field: "athlete", filter: "agTextColumnFilter", minWidth: 200 },
        { field: "age" },
        { field: "country", minWidth: 180 },
        { field: "year" },
        { field: "date", minWidth: 150 },
        { field: "goldd" },
        { field: "silver" },
        { field: "bronze" },
        { field: "total" },
    ]);
    const gridRef = useRef(null);
    const [ rowData, setRowData ] = useState();
    // const onGridReady = useCallback((params) => {
    //     fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
    //         .then((resp) => resp.json())
    //         .then((data) => setRowData(data));
    // }, []);

    const [ selectedItems, setSelectedItems ] = useState([]);

    useEffect(() => {
        setSelectedItems(userInfo.datasources[ currentDatasourceId ]?.namespaces || []);
    }, [ userInfo.datasources, currentDatasourceId ]);



    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            sortable: true,
            filter: true,
        };
    }, []);

    const icons = useMemo(() => {
        return {
            "custom-stats": '<span class="ag-icon ag-icon-custom-stats"></span>',
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
        };
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    minWidth: 225,
                    width: 225,
                    maxWidth: 225,
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
                {
                    id: "customStats",
                    labelDefault: "Custom Stats",
                    labelKey: "customStats",
                    iconKey: "custom-stats",
                    toolPanel: CustomStatsToolPanel,
                },
            ],
            position: "left",
            defaultToolPanel: "customStats",
        };
    }, []);

    const handleNewDSOpenChange = (open) => {
        setNewDSPopoverOpen(open);
    };

    const handleEditDSOpenChange = (open) => {
        setEditDSPopoverOpen(open);
    };

    const onCreateDSFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        alert("Failed:" + errorInfo);
    };
    const getSchemaSource = (schema) => {
        // Each key of this object is a namespace name
        // These namespaces have keys called 'tables' which contain table names and details
        // Let's print just the namespace, table name and column name for now
        let result = [];

        for (const [ namespaceKey, namespaceValue ] of Object.entries(schema.namespaces)) {
            for (const [ tableKey, tableValue ] of Object.entries(namespaceValue.tables)) {
                let colName = "";

                if (tableValue.hasOwnProperty("direct_fields")) {
                    for (const field of tableValue.direct_fields) {
                        colName = colName + Object.keys(field) + ", ";
                    }
                    colName = colName.slice(0, -2);  // Remove trailing comma and space
                }

                let obj = {
                    namespace: namespaceKey,
                    table: tableKey,
                    columns: colName,
                    key: namespaceKey + "." + tableKey,  // Key is combination of namespace and table
                };
                result.push(obj);
            }
        }

        console.log("Schema source:")
        console.log(result);
        return result;
    };

    const getSchema = () => {
        let schemaOutputColumns = [
            {
                title: "Namespace",
                dataIndex: "namespace",
                key: "namespace",
            },
            {
                title: "Table",
                dataIndex: "table",
                key: "table",
            },
            {
                title: "Columns",
                dataIndex: "columns",
                key: "columns",
            },
        ];
        return (
            <Table
                dataSource={getSchemaSource(userInfo.datasources[ currentDatasourceId ].schema)}
                columns={schemaOutputColumns}
            />
        );
    };


    const onCreateDSFinish = (values) => {
        // create a new Datasource, on done refresh the UI - add it to the local storage as well as remotely
        // you can be optimistic and update the UI right away but if it fails you need to update it again to the old state
        fetch(rootURI + "/users/" + userObject.user_id + "/datasources/test/", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: userObject.user_token,
            },
            body: JSON.stringify(values),
        })
            .then((res) => res.json())
            .then((result) => {
                if (!result.errors) {
                    setNewDSPopoverOpen(false);
                    let newDS = result;
                    if (!newDS.hasOwnProperty("questions")) {
                        newDS.questions = {};
                    }
                    newDS.updated_at = newDS.created_at;
                    const { created_at, ...others } = newDS;
                    let newUserInfo = userInfo;
                    newUserInfo.datasources[ others.id ] = others;
                    setUserInfo(newUserInfo);
                    alert("Datasource " + newDS.name + " has been added successfully");
                } else {
                    setNewDSPopoverOpen(false);
                    alert("DataSource could not be added: " + result.errors);
                }
            });
    };

    const onEditDSFinish = (values) => {
        fetch(rootURI + "/users/" + userObject.user_id + "/datasources/" + currentDatasourceId, {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: userObject.user_token,
            },
            body: JSON.stringify(values),
        })
            .then((res) => res.json())
            .then((result) => {
                if (!result.errors) {
                    setEditDSPopoverOpen(false);
                    let newDS = result;
                    newDS.updated_at = newDS.created_at;
                    if (!newDS.hasOwnProperty("questions")) {
                        newDS.questions = {};
                    }
                    const { created_at, ...others } = newDS;
                    let newUserInfo = userInfo;
                    newUserInfo.datasources[ others.id ] = others;
                    setUserInfo(newUserInfo);
                    alert("Datasource " + newDS.name + " has been updated successfully");
                } else {
                    setEditDSPopoverOpen(false);
                    alert("DataSource could not be updated: " + result.errors);
                }
            });
    };

    function generateTableCounts(schema) {
        const counts = {};

        for (const namespaceData of Object.values(schema.namespaces)) {
            for (const table of Object.keys(namespaceData.tables)) {
                if (counts[ table ]) {
                    counts[ table ]++;
                } else {
                    counts[ table ] = 1;
                }
            }
        }

        return counts;
    }

    function onSelectedNamespacesChange(value) {
        // Update the remote datasource object with the new namespaces
        let newDS = {};

        newDS.namespaces = value;
        fetch(rootURI + "/users/" + userObject.user_id + "/datasources/" + currentDatasourceId, {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: userObject.user_token,
            },
            body: JSON.stringify({ datasource: { namespaces: value } }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (!result.errors) {
                    let newUserInfo = userInfo;
                    newUserInfo.datasources[ currentDatasourceId ].namespaces = result.namespaces;
                    newUserInfo.datasources[ currentDatasourceId ].schema = result.schema;
                    setUserInfo(newUserInfo);
                    setSelectedItems(value);
                } else {
                    alert("DataSource could not be updated: " + result.errors);
                }
            });

    }
    function handleChange(value) {
        setCurrentDatasourceId(value);
        let schema = userInfo.datasources[ value ].schema;
        // calculate all the schema paths using Floyd-Warshall
        // and then save the data on the userInfo object
        userInfo.datasources[ value ].allPaths = floydWarshallAllShortestPaths(schema);

        // Calculate which table names exist more than once in all namespaces and prepend them with the namespace when that is the case
        userInfo.datasources[ value ].tableCounts = generateTableCounts(schema);
    }

    function confirmDeleteDatasource(e) {
        setLoading(true);
        fetch(rootURI + "/users/" + userObject.user_id + "/datasources/" + currentDatasourceId, {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: userObject.user_token,
            },
        })
            .then((result) => {
                if (!result.errors) {

                    let newUserInfo = userInfo;
                    delete newUserInfo.datasources[ currentDatasourceId ];
                    setUserInfo(newUserInfo);
                    setCurrentDatasourceId(-1);
                    message.success('Datasource deleted');
                } else {
                    message.success('DataSource could not be deleted: ' + result.errors);
                }
                setLoading(false);
            }
            );
    }

    return (
        <>
            <Divider orientation="left" orientationMargin={0}>
                <Space>
                    Data source
                    <div style={{ display: "inline-block" }}>
                        {Object.keys(userInfo.datasources).length > 0 && (
                            <Select
                                defaultValue={"--- select datasource ---"}
                                style={{
                                    width: "100%",
                                }}
                                onChange={handleChange}
                                dropdownStyle={{ minWidth: "50%" }}
                            >
                                {userInfo.datasources
                                    ? Object.entries(userInfo.datasources).map(([ key, val ]) => {
                                        return (
                                            <Option value={key} key={val.user_id.toString() + val.id.toString()}>
                                                {val.name}
                                            </Option>
                                        );
                                    })
                                    : ""}
                            </Select>
                        )}
                    </div>

                    {currentDatasourceId > -1 ? (
                        <div style={{ display: "inline-block" }}> <Popconfirm
                            title="Delete datasource"
                            description="Are you sure to delete this datasource?"
                            onConfirm={confirmDeleteDatasource}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>Delete</Button>
                        </Popconfirm>
                        </div>) : ""}

                    <div style={{ display: "inline-block" }}>
                        <DatasourcePopover
                            trigger="click"
                            // content={content}
                            placement="bottom"
                            buttonText="Update"
                            onFinish={onEditDSFinish}
                            onFinishFailed={onCreateDSFinishFailed}
                            initialValues={userInfo.datasources[ currentDatasourceId ]}
                            open={editDSPopoverOpen}
                            onOpenChange={handleEditDSOpenChange}
                        >
                            {currentDatasourceId > 0 && <Button> Edit Datasource</Button>}
                        </DatasourcePopover>
                    </div>
                    <div style={{ display: "inline-block" }}>
                        <Popover trigger="click" content={getSchema} placement="bottom">
                            {currentDatasourceId > 0 && <Button> Show Schema</Button>}
                        </Popover>
                    </div>
                    <div style={{ display: "inline-block" }}>
                        <DatasourcePopover
                            onFinish={onCreateDSFinish}
                            onFinishFailed={onCreateDSFinishFailed}
                            open={newDSPopoverOpen}
                            onOpenChange={handleNewDSOpenChange}
                            trigger="click"
                            // content={content}
                            placement="bottom"
                            buttonText="Create"
                        >
                            <Button>New datasource</Button>
                        </DatasourcePopover>
                    </div>
                    {Object.keys(userInfo.datasources[ currentDatasourceId ]?.schema?.namespaces || {}).length > 0 &&
                        (userInfo.datasources[ currentDatasourceId ].db_type === "PostgreSQL" || userInfo.datasources[ currentDatasourceId ].db_type === "Snowflake") && (
                            <div style={{ display: "inline-block", }}>
                                Enabled schemas: <Select
                                    mode="multiple"
                                    placeholder="Select active schemas"
                                    value={selectedItems}
                                    onChange={onSelectedNamespacesChange}
                                    style={{
                                        width: '100%',
                                        // "margin-right": "150px"
                                    }}
                                    options={Object.keys(userInfo.datasources[ currentDatasourceId ]?.schema?.namespaces || {}).map(item => ({ value: item, label: item }))}
                                />

                            </div>)}{" "}
                </Space>
            </Divider>{" "}
            {Object.keys(userInfo.datasources).length > 0 && (
                <QuestionSelect
                    currentDatasourceId={currentDatasourceId}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    setColumnDefs={setColumnDefs}
                    setRowData={setRowData}
                    setCode={setCode}
                ></QuestionSelect>
            )}{" "}
            <Divider orientation="left" orientationMargin={0}>
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ width: "100%" }}>
                        <Tabs
                            style={{ paddingLeft: "10px" }}
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: `Results`,
                                    key: "1",
                                    children: (
                                        <div className="ag-theme-alpine" style={{ height: 600, width: 1600 }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                rowData={rowData}
                                                columnDefs={columnDefs}
                                                // onGridReady={onGridReady}
                                                // defaultColDef={defaultColDef}
                                                icons={icons}
                                                // sideBar={sideBar}
                                                // autoGroupColumnDef={autoGroupColumnDef}
                                                overlayLoadingTemplate={
                                                    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                                                }
                                            ></AgGridReact>
                                        </div>
                                    ),
                                },
                                {
                                    label: `Visualization`,
                                    key: "2",
                                    children: (
                                        <div className="ag-theme-alpine" style={{ height: 600, width: 1600 }}>
                                            <Charting
                                                columnDefs={columnDefs}
                                                rowData={rowData}
                                            />
                                        </div>
                                    )
                                },
                                {
                                    label: `Code`,
                                    key: "3",
                                    children: (
                                        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                            {code}</div>
                                    )

                                },
                            ]}
                        />
                    </div>
                </div>
            </Divider>
        </>
    );
};
export default DatasourceList;
