import { Button, Popover, Form, Input, Select } from "antd";
import React, { useState } from 'react';
const { Option } = Select;

const DatasourcePopover = ({
	onFinish,
	onFinishFailed,
	open,
	onOpenChange,
	buttonText,
	initialValues = {},
	children,
}) => {
	const [ schemaIdentifier, setSchemaIdentifier ] = useState('DB Name');
	const [ hostIdentifier, setHostIdentifier ] = useState('DB host');
	const [ portIdentifier, setPortIdentifier ] = useState('DB port');

	return (
		<Popover
			content={
				<Form
					name="basic"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					initialValues={initialValues}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Form.Item
						name="db_type"
						label="DB Type"
						rules={[
							{
								required: true,
								message: "Please select the type of your database"
							},
						]}
					>
						<Select
							placeholder="DB Type"
							onChange={(value: string) => {
								if (value == 'MySQL') {
									setSchemaIdentifier('SchemaName');
									setHostIdentifier('Host');
									setPortIdentifier('Port');
								}
								else if (value == 'PostgreSQL') {
									setSchemaIdentifier('DB Name');
									setHostIdentifier('DB host');
									setPortIdentifier('DB port');
								}
								else if (value == 'Snowflake') {
									setSchemaIdentifier('Database');
									setHostIdentifier('Server');
									setPortIdentifier('Warehouse');

								}
							}}

						>
							<Option value="PostgreSQL">PostgreSQL</Option>
							<Option value="Snowflake">Snowflake</Option>
							<Option value="MySQL">MySQL</Option>

						</Select>
					</Form.Item>

					<Form.Item
						label="Name"
						name="name"
						rules={[
							{
								required: true,
								message: "Please enter a unique name for this datasource",
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label={hostIdentifier}
						name="host"
						rules={[
							{
								required: true,
								message: "Please input the address of the DB server",
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label={portIdentifier}
						name="port"
						rules={[
							{
								required: true,
								message: (portIdentifier === "DB port" ? "Please input the DB port" : "Please input the DB warehouse"),
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label={schemaIdentifier}
						name="db_name"
						rules={[
							{
								required: true,
								message: "Please input the DB name",
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="username"
						name="username"
						rules={[
							{
								required: true,
								message: "Please input the DB username",
							},
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="DB password"
						name="password"
						rules={[
							{
								required: true,
								message: "Please input the DB password",
							},
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						wrapperCol={{
							offset: 8,
							span: 16,
						}}
					>
						<Button type="primary" htmlType="submit">
							{buttonText}
						</Button>
					</Form.Item>
				</Form>
			}
			trigger="click"
			placement="right"
			open={open}
			onOpenChange={onOpenChange}
		>
			{children}
		</Popover>
	);
};

export default DatasourcePopover;