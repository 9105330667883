import React, { useContext, useEffect, useState } from "react";
import { Button, Cascader, Select, Popover, Divider, Space, Input, message, Popconfirm } from "antd";
import { deepCopy } from "../../utils/utils.js";
import { rootURI } from "../../utils/common_definitions.js";
import { Question } from "./Question.jsx";
import UserContext from "../../Contexts.js";
import { set } from "lodash";

const { Option } = Select;
const { Search } = Input;




export function QuestionSelect(props) {
  const { userObject, setUserObject } = useContext(UserContext);
  const [ currentQuestionId, setCurrentQuestionId ] = useState(-1);
  const questionMap =
    props.currentDatasourceId > -1 ? props.userInfo?.datasources[ props.currentDatasourceId ].questions : {};
  const options = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const newQuestionContent =
    props.currentDatasourceId > 0 ? (
      <Search placeholder="Question name" enterButton="Create" onSearch={createQuestion}></Search>
    ) : (
      <p>Please select a datasource first</p>
    );

  useEffect(() => {
    setCurrentQuestionId(-1);
  }, [ props.currentDatasourceId ]);

  function handleChange(value) {
    setCurrentQuestionId(value);
  }

  function createQuestion(value) {
    fetch(rootURI + "/users/" + userObject.user_id + "/datasources/" + props.currentDatasourceId + "/questions/", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userObject.user_token,
      },
      body: JSON.stringify({ name: value }),
    })
      .then((res) => res.json())
      .then((result) => {
        let newUserInfo = deepCopy(props.userInfo);
        if (!newUserInfo.datasources[ props.currentDatasourceId ].questions) {
          newUserInfo.datasources[ props.currentDatasourceId ].questions = {};
        }
        newUserInfo.datasources[ props.currentDatasourceId ].questions[ result.id ] = deepCopy(result);
        props.setUserInfo(newUserInfo);
        setCurrentQuestionId(result.id);
      });
  }
  // TODO LATER
  function setGroupByFields(colDefs, newQuestion) {
    // if the question contains any "for each" nodes, then we need to group by the fields in that table
    newQuestion.nodes.filter(node => node.operation === "for each").forEach(node => {
      let tableSchema = props.userInfo.datasources[ props.currentDatasourceId ].schema[ node.collection ];
      let primary_key = tableSchema.primary_key;
      let incoming_foreign_keys = Object.values(tableSchema.inbound_relationships).map(rel => rel.referenced_column);
      let outgoing_foreign_keys = Object.values(tableSchema.outbound_relationships).map(rel => rel.source_column);

      let groupByField = tableSchema.direct_fields.filter(field => {
        let field_name = Object.values(field)[ 0 ];
        return !primary_key.includes(field_name) && !incoming_foreign_keys.includes(field_name) && !outgoing_foreign_keys.includes(field_name);
      });

      if (groupByField.length > 0) {

      }
    });
  }

  function updateQuestion(newQuestion, set_show_me_button_text) {
    // We use runQuery as a parameter to avoid making multiple calls
    fetch(
      rootURI +
      "/users/" +
      userObject.user_id +
      "/datasources/" +
      props.currentDatasourceId +
      "/questions/" +
      currentQuestionId,
      {
        method: "PATCH",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userObject.user_token,
        },
        body: JSON.stringify({ "question": newQuestion, "runQuery": true }),
      }
    )
      .then((res) => {
        if (set_show_me_button_text) {
          set_show_me_button_text("Show Me");
        }

        if (!res.ok) {
          message.error("There was an error: " + res.status + ". Please check if the question is valid.");
        }
        return res.json();
      })
      .then((result) => {
        if (result.error) {
          message.error(result.error);
          return;
        }
        message.success("Data refreshed successfully");
        // .data holds the data and .sql holds the generated string.
        if (result.data?.length > 0) {
          let colDefs = Object.keys(result.data[ 0 ]).map(col_name => { return { "field": col_name }; });
          // If the the nodes contain more than one list of, for each on make a master-detail grid
          // and for each "for each" group the rows by 
          // 1. a non-pk, non-fk field that is text
          // 2. a non-pk, non-fk field that is numberic
          // 3. a non-pk, non-fk field that is date
          // 4. a non-pk, non-fk field that is boolean
          // 5. pk field
          // setGroupByFields(colDefs, newQuestion);
          // setMasterDetailGroups(colDefs, newQuestion);

          props.setColumnDefs(colDefs);
          props.setRowData(result.data);
          props.setCode(result.sql);
        } else {
          props.setColumnDefs(null);
          props.setRowData(null);
          props.setCode('');
        }

        console.log(result);
        let newUserInfo = deepCopy(props.userInfo);
        newUserInfo.datasources[ props.currentDatasourceId ].questions[ currentQuestionId ] = deepCopy(newQuestion);
        props.setUserInfo(newUserInfo);
      });
  }

  const confirmDeleteQuestion = (e) => {
    fetch(
      rootURI + "/users/" + userObject.user_id + "/datasources/" + props.currentDatasourceId + "/questions/" + currentQuestionId,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userObject.user_token,
        },
      }
    )
      .then((result) => {
        let newUserInfo = deepCopy(props.userInfo);
        delete newUserInfo.datasources[ props.currentDatasourceId ].questions[ currentQuestionId ];
        props.setUserInfo(newUserInfo);
        setCurrentQuestionId(-1);
        message.success('Question deleted');
      });


  };

  return (
    <>
      <Divider orientation="left" orientationMargin={0}>
        <Space>
          Question
          <div>
            <div style={{ display: "inline-block" }}>
              <Select
                value={currentQuestionId > -1 ? currentQuestionId.toString() : "--- select or create question ---"}
                style={{
                  width: "100%",
                }}
                dropdownStyle={{ minWidth: "50%" }}
                onChange={handleChange}
                options={
                  questionMap
                    ? Object.entries(questionMap).map(([ key, val ]) => {
                      return { label: val.name, value: key };
                    })
                    : []
                }
              ></Select>
            </div>
            {currentQuestionId > -1 ? (
              <div style={{ display: "inline-block" }}> <Popconfirm
                title="Delete question"
                description="Are you sure to delete this question?"
                onConfirm={confirmDeleteQuestion}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Delete</Button>
              </Popconfirm>
              </div>) : ""}
            <Popover trigger="hover" content={newQuestionContent} placement="top" title="Create new question">
              <Button>New question</Button>
            </Popover>
          </div>
        </Space>
      </Divider>
      {currentQuestionId > -1 ? (
        <Question
          questionId={currentQuestionId}
          currentDatasourceId={props.currentDatasourceId}
          updateQuestion={updateQuestion}
          userInfo={props.userInfo}
          setUserInfo={props.setUserInfo}
          setCode={props.setCode}
        ></Question>
      ) : (
        ""
      )}
    </>
  );
}
export default QuestionSelect;
