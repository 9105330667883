import React, { useState, useRef } from "react";
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder";
import { Button, Popover, Row, Col, Input } from "antd";
import { Typography } from "antd";
import { MySQLTypeMapper, PGTypeMapper } from "../../utils/utils";
const { Text } = Typography;

export function FilterPopover(props) {
	const [ filterRules, setFilterRules ] = useState(props.question.nodes[ props.nodeIndex ].booleanQuery);
	const [ filterState, setFilterState ] = useState(isRuleComplete(filterRules) ? "some" : "all");
	console.log(props.question.nodes[ props.nodeIndex ].booleanQuery);
	const handlePopoverOpenChange = (newOpen) => {
		if (newOpen === true) {
			props.setCascaderDisabled(newOpen);
			setOpen(newOpen);
		}
	};
	const enableFilter = (event) => {
		setFilterState("some");
	};
	const handleFiltersClick = (event) => {
		setFilterState("all");
	};
	let QueryBuilderFieldMode = 'DropdownTree';
	let QueryBuilderSeparator = '\'s ';

	// Given a schema, extracts the columns and their types
	// If the path contains one table, returns just a list of fields (1x for loop)
	// If the path contains multiple tables, prefixes the fields with the table name (Table.Field - 2x for loop)
	function getColumnData(question, nodeIndex, schema) {
		let columns = [];
		let node = question.nodes[ nodeIndex ];
		let path = node.path;

		function getTableColumns(namespace, table, schema) {
			let columns = [];
			let directFields = schema.namespaces[ namespace ]?.tables[ table ]?.direct_fields || [];
			for (const field of directFields) {
				const name = Object.keys(field)[ 0 ];
				let type = Object.values(field)[ 0 ];
				type = props.datasource.db_type === "PostgreSQL" ? PGTypeMapper(type) : MySQLTypeMapper(type);
				columns.push({ field: name, label: name, type: type });
			}
			return columns;
		}

		// If there's just the source field in the path
		if (!path[ 0 ].target) {
			const namespace = path[ 0 ].source.namespace;
			const tableName = path[ 0 ].source.table;

			columns = getTableColumns(namespace, tableName, schema);

			// If the nodeIndex > 0 or the operation is an aggregation, add extra columns as needed
			// The following code handles special cases for certain operations
			switch (node.operation) {
				// Handle specific cases here...

				// do nothing for all other operations
				default:
					break;
			}

			return columns;
		} else {
			for (let i = 0; i < path.length; i++) {
				const namespace = path[ i ].source.namespace;
				const tableName = path[ i ].source.table;
				columns.push({
					field: tableName, label: tableName,
					columns: getTableColumns(namespace, tableName, schema)
				});

				// Add the last table as well
				if (i === path.length - 1) {
					const targetNamespace = path[ i ].target.namespace;
					const targetTable = path[ i ].target.table;
					columns.push({
						field: targetTable, label: targetTable,
						columns: getTableColumns(targetNamespace, targetTable, schema)
					})
				}
			}
			return columns;
		}
	}

	function isRuleComplete(filters) {
		// Check if the current rule is complete
		if (
			filters && filters.label && filters.label !== "" &&
			filters.field && filters.field !== "" &&
			filters.type && filters.type !== "" &&
			filters.operator && filters.operator !== "" &&
			filters.value && filters.value !== ""
		) {
			return true;
		}

		// If there are nested rules, check them recursively
		if (filters && filters.rules) {
			for (let rule of filters.rules) {
				if (isRuleComplete(rule)) {
					return true;
				}
			}
		}

		// If no complete rule was found, return false
		return false;
	}


	function handleQBChange(event) {
		// Save the instance so we can use it to convert the rules to SQL
		console.log(this.getRules());
		// console.log(this.getSqlFromRules());
		setFilterRules(this.getRules());
		if (isRuleComplete(this.getRules())) {
			setFilterState("some");
		} else {
			setFilterState("all");
		}
	}


	const content = (
		<div onMouseDown={e => e.stopPropagation()}>
			<QueryBuilderComponent
				rule={props.question.nodes[ props.nodeIndex ].booleanQuery}
				width='100%'
				fieldMode={QueryBuilderFieldMode}
				separator={QueryBuilderSeparator}
				change={handleQBChange}
				columns={getColumnData(props.question, props.nodeIndex, props.schema)}></QueryBuilderComponent>
		</div>
	);
	const [ open, setOpen ] = useState(false);

	const hidePopover = () => {
		if (open === true) {
			setOpen(false);
			props.setCascaderDisabled(false);
			let question = props.question;
			let nodeIndex = props.nodeIndex;
			// Update the question both locally and remotely with the new filters (if different than before)
			if (question.nodes[ nodeIndex ].booleanQuery !== filterRules) {
				question.nodes[ nodeIndex ].booleanQuery = filterRules;
				props.updateQuestion(question);
			}
		}
	};


	return (
		<>
			<Popover onOpenChange={handlePopoverOpenChange}
				content={content}
				placement="top"
				title={<Row><Col span={11}>Filters</Col><Col span={12}><Button onClick={hidePopover} >Close</Button></Col></Row >}
				trigger="click"
				open={open}
			>
				<Button type="link" onClick={hidePopover} >{filterState}</Button>
			</Popover >
			<Text key={props.label}>{props.label}</Text>
		</>
	);
}
