import React, { useState, useRef, useContext } from "react";
import { Cascader, Menu, Button, Checkbox, Input } from "antd";
import { Typography } from "antd";
import { operationValues, rootURI } from "../../utils/common_definitions.js";
import { deepCopy, getSchemaPaths, floydWarshallAllShortestPaths, PGTypeMapper, uuidv4, MySQLTypeMapper } from "../../utils/utils.js";

import { getValidOperations, getValidCollectionsAndFields } from "../../utils/utils.js";
import { FilterPopover } from "./FilterPopover.jsx";
import UserContext from "../../Contexts.js";

const { Text } = Typography;

export function QuestionNode(props) {
  const nodeIndex = props.nodeIndex;
  const schema = props.schema;
  const question = props.question;
  const datasource = props.datasource;
  const node = question.nodes[ nodeIndex ];
  const cascaderRef = useRef(null);

  const { userObject, setUserObject } = useContext(UserContext);
  const [ cascaderDisabled, setCascaderDisabled ] = useState(false);
  const [ options, setOptions ] = useState(generateNodeMenu(nodeIndex, question, schema));
  const [ cascaderValue, setCascaderValue ] = useState(getNodeValueFromOptions(node, options));
  const [ searchString, setSearchString ] = useState("");


  function getNodeValueFromOptions(node, options) {
    if (node.operation === "") {
      return "";
    }

    for (const option of options) {
      if (option.operation && option.operation === node.operation &&
        option.collection.namespace === node.collection.namespace && option.collection.table === node.collection.table) {
        if (node.selectedField && option.selectedField !== node.selectedField) continue;
        if (node.transformation && option.transformation !== node.transformation) continue;
        console.log("Node value: ");
        console.log(option.value);
        return option.value;
      }
    }

    for (const option2 of options) {
      if (option2.children?.length > 0) {
        const match = getNodeValueFromOptions(node, option2.children);
        if (match) {
          console.log("Node value: ");
          console.log([ option2.value, match ].flat());
          return [ option2.value, match ].flat();
        }
      }
    }
  }

  // Function to get the full name
  function getTableFullName(obj, tableCounts) {
    if (tableCounts[ obj.table ] > 1) {
      return `${obj.namespace}.${obj.table}`;
    } else {
      return obj.table;
    }
  }


  // It would be nice for this code to be in Question instead
  // but we cannot pass nodeIndex so we might not be able to tell between two nodes
  // in the case of a self join for example
  function handleNodeChangeWithIndex(nodeIndex, props) {
    return function handleNodeChange(values, selectedOptions) {
      console.log("new onchange value:" + values);
      // If the node was cleared set all values to ''
      let newNode = {};

      if (values === undefined) {
        newNode = { operation: "", collection: { namespace: "", table: "" }, selectedField: "" };
      } else {
        let selectedOptionInfo = selectedOptions[ selectedOptions.length - 1 ];
        newNode[ "operation" ] = selectedOptionInfo.operation;
        newNode[ "collection" ] = selectedOptionInfo.collection;
        newNode[ "path" ] = selectedOptionInfo.path;
        if ([ "for each", "sum of", "number of", "average" ].includes(newNode.operation)) {
          newNode[ "firstDifferentTable" ] = selectedOptionInfo?.firstDifferentTable;
          newNode[ "selectedField" ] = selectedOptionInfo?.selectedField;
          newNode[ "transformation" ] = selectedOptionInfo?.transformation;
        }
      }

      // Edit the existing node's operation and remove all nodes after
      let newNodeList = props.question.nodes.slice(0, nodeIndex).concat([ newNode ]);

      if (newNode.operation !== "") {
        newNodeList.push({
          operation: "",
          collection: { namespace: "", table: "" },
          selectedField: "",
        });
      }

      let newQuestion = { ...props.question, nodes: newNodeList };
      setCascaderValue(values)
      props.updateQuestion(newQuestion);
    }
  }

  function generateNodeMenu(nodeIndex, question, schema) {
    const menu = [];
    const validOperations = getValidOperations(nodeIndex, question);

    if (nodeIndex === 0) {
      validOperations.reverse();
    }

    for (const operation of validOperations) {
      const { validFieldsByCollection, validCollections } = getValidCollectionsAndFields(operation, nodeIndex, question, schema, datasource.namespaces);
      const uniqueVal1 = uuidv4();
      const operationOption = {
        value: uniqueVal1,
        key: uniqueVal1,
        label: getOperationLabelPrefix(operation, nodeIndex, question) + operation,
        children: [],
      };

      if (operation === "list of" && nodeIndex === 0) {
        for (const [ collectioNamespace, collectionTable ] of validCollections) {
          const uniqueVal = uuidv4();
          let collection = { 'namespace': collectioNamespace, 'table': collectionTable };
          const menuOption = {
            value: uniqueVal,
            operation,
            nodeIndex,
            label: `all ${collection.table}`,
            key: uniqueVal,
            collection,
            path: [ { source: collection } ],
          };
          menu.push(deepCopy(menuOption));
        }
        continue;
      }

      for (const [ collectionNamespace, collectionTable ] of validCollections) {
        // console.log("Collection: " + collectionNamespace + "." + collectionTable);
        const primary_key = schema.namespaces[ collectionNamespace ].tables[ collectionTable ].primary_key;
        let collection = { 'namespace': collectionNamespace, 'table': collectionTable };
        let collectionPaths = [ [ { source: collection } ] ];
        let label = collectionTable;

        if (nodeIndex > 0) {
          let previousNodeCollection = collection;

          if (operation === "for each") {
            for (let i = nodeIndex - 1; i >= 0; i--) {
              if ([ "list of", "sum of", "number of", "average" ].includes(question.nodes[ i ].operation)) {
                previousNodeCollection = question.nodes[ i ].collection;
                break;
              }
            }
          } else if ([ "list of", "sum of", "number of", "average" ].includes(operation)) {
            for (let i = nodeIndex - 1; i >= 0; i--) {
              if (question.nodes[ i ].operation === "list of") {
                previousNodeCollection = question.nodes[ i ].collection;
                break;
              }
            }
          }

          const allPaths = props.userInfo.datasources[ question.datasource_id ].allPaths;
          collectionPaths = allPaths[ previousNodeCollection.namespace ][ previousNodeCollection.table ][ collection.namespace ][ collection.table ];
        }

        if (operation === "for each") {
          collectionPaths.sort((a, b) => a.length - b.length);
        }

        for (const currentPath of collectionPaths) {
          let uniqueVal2 = uuidv4();
          let collectionChild = {
            key: uniqueVal2,
            value: uniqueVal2,
            collection,
            path: deepCopy(currentPath),
            firstDifferentTable: {},
          };
          const fieldList = [];

          if (collectionPaths.length > 1) {
            for (let pathIdx = 0; pathIdx < currentPath.length; pathIdx++) {
              if (collectionPaths.some(path => path[ pathIdx ].target.namespace !== currentPath[ pathIdx ].target.namespace || path[ pathIdx ].target.table !== currentPath[ pathIdx ].target.table)) {
                collectionChild.firstDifferentTable = currentPath[ pathIdx ].target;
                break;
              }
              if (collectionPaths.some(path => path[ pathIdx ].source.namespace !== currentPath[ pathIdx ].source.namespace || path[ pathIdx ].source.table !== currentPath[ pathIdx ].source.table)) {
                collectionChild.firstDifferentTable = currentPath[ pathIdx ].source;
                break;
              }
            }
            label = getTableFullName(collectionChild.firstDifferentTable, props.userInfo.datasources[ question.datasource_id ].tableCounts) + "\'s " + collectionTable;
          }

          collectionChild.label = label;

          if (validFieldsByCollection &&
            validFieldsByCollection[ collectionNamespace ] &&
            validFieldsByCollection[ collectionNamespace ][ collectionTable ]
          ) {
            for (const field of validFieldsByCollection[ collectionNamespace ][ collectionTable ]) {
              const fieldType = datasource.db_type === "PostgreSQL" ? PGTypeMapper(Object.values(field)[ 0 ]) : MySQLTypeMapper(Object.values(field)[ 0 ]);
              // console.log("Field type:" + fieldType);
              const fieldName = Object.keys(field)[ 0 ];
              // console.log("Field name:" + fieldName);
              const fieldValue = uuidv4();
              const fieldChild = {
                value: fieldValue,
                key: fieldValue,
                label: fieldName,
                selectedField: fieldName,
                operation,
                collection,
                path: currentPath,
                firstDifferentTable: collectionChild.firstDifferentTable,
              };

              if (fieldType === "number" && operation === "for each" && !primary_key.includes(fieldName)) {
                let [ k1, k2, k3 ] = [ uuidv4(), uuidv4(), uuidv4() ];
                fieldChild.children = [
                  { ...fieldChild, label: "exact", aggregation: "exact", key: k1, value: k1 },
                  { ...fieldChild, label: "split into 10 intervals", aggregation: "split into 10 intervals", key: k2, value: k2 },
                  { ...fieldChild, label: "split into 100 intervals", aggregation: "split into 100 intervals", key: k3, value: k3 },
                ];
                delete fieldChild.operation;
              }

              if (fieldType === "date" && operation === "for each") {
                let [ k1, k2, k3, k4, k5 ] = [ uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4() ];
                fieldChild.children = [
                  { ...fieldChild, label: "exact", aggregation: "exact", key: k1, value: k1 },
                  { ...fieldChild, label: "by year", aggregation: "by year", key: k2, value: k2 },
                  { ...fieldChild, label: "by month", aggregation: "by month", key: k3, value: k3 },
                  { ...fieldChild, label: "by week", aggregation: "by week", key: k4, value: k4 },
                  { ...fieldChild, label: "by day", aggregation: "by day", key: k5, value: k5 },
                ];
                delete fieldChild.operation;
              }

              fieldList.push(deepCopy(fieldChild));
            }
          } else {
            collectionChild.operation = operation;
          }

          collectionChild.children = deepCopy(fieldList);
          operationOption.children.push(deepCopy(collectionChild));
        }
      }

      operationOption.children.sort((a, b) => a.path.length - b.path.length);
      menu.push(deepCopy(operationOption));
    }

    console.log(menu);
    return menu;
  }

  function getOperationLabelPrefix(operation, nodeIndex, question) {
    let operationLabelPrefix = "";

    if (nodeIndex > 0) {
      if (operation === "for each") {
        if (question.nodes[ nodeIndex - 1 ].operation === "for each") {
          operationLabelPrefix = 'and ';
        } else {
          operationLabelPrefix = ''
        }
      } else {
        operationLabelPrefix = 'and their ';
      }
    } else {
      operationLabelPrefix = "the ";
    }
    return operationLabelPrefix;
  }


  function displayRender(schema, question, nodeIndex, props) {
    return function (labels, selectedOptions) {
      if (question.nodes[ nodeIndex ].operation === "") {
        return [ "" ];
      }

      let dataOption = selectedOptions[ selectedOptions.length - 1 ];

      let operationLabelPrefix = getOperationLabelPrefix(question.nodes[ nodeIndex ].operation, nodeIndex, question);

      return (
        <>
          <Text>{operationLabelPrefix + dataOption.operation}</Text>
          <FilterPopover
            label={" " + (dataOption.firstDifferentTable && dataOption.firstDifferentTable.table ? getTableFullName(dataOption.firstDifferentTable, props.userInfo.datasources[ question.datasource_id ].tableCounts) + "'s " : "") + getTableFullName(dataOption.collection, props.userInfo.datasources[ question.datasource_id ].tableCounts) + (dataOption.selectedField && dataOption.selectedField !== "" ? " " + dataOption.selectedField : "")}
            option={dataOption}
            setCascaderDisabled={setCascaderDisabled}
            key={dataOption.value}
            schema={schema}
            nodeIndex={nodeIndex}
            question={question}
            datasource={datasource}
            updateQuestion={props.updateQuestion}
          ></FilterPopover>
        </>
      );

    }
  }


  function filter(inputValue, path) {
    // return false;
    // console.log("input");
    // console.log(inputValue);
    // console.log("PATH TO FILTER");
    // console.log(path);
    const words = inputValue.split(/[\s.,;:!?(){ }[\]"']+/).filter(w => w !== '');

    return words.some(word => path.some(option => option.label.toLowerCase().includes(word.trim().toLowerCase())));
  }


  let style = {};
  if (nodeIndex === question.nodes.length - 1) {
    style = { width: "100%" };
  }

  const renderOptions = (inputValue, path) => {
    // console.log("renderOptions");
    // console.log(inputValue);
    // console.log(path);
    return (<Text>{path.map(node => node.label).join(" ")}</Text>)
    // if the user pressed Enter to search
    // take the result(s) from ChatGPT and render them as Question(s)
  }

  const dropdownMenuColumnStyle = {
    // height: '300px', // Adjust the height to show more options
    width: '100%', // Adjust the width to make the dropdown wider
  };

  return (
    <div style={style}>

      <Cascader
        ref={cascaderRef}
        options={options}
        disabled={cascaderDisabled}
        value={cascaderValue}
        displayRender={displayRender(schema, question, nodeIndex, props)}
        onChange={handleNodeChangeWithIndex(nodeIndex, props)}
        dropdownMenuColumnStyle={dropdownMenuColumnStyle}
        placeholder="Type your question or select from the menu"
        size="large"
        style={{
          width: "100%",
          minWidth: "250px",
        }}
        showSearch={{
          filter,
          limit: 50,
          render: renderOptions
        }}
      />
    </div >
  );
}
